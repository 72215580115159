table,
th,
td {
	border: 1px solid;
	white-space: pre;
	font-family: 'Courier New', Courier, monospace;
	text-align: center;
}

table {
	border-collapse: collapse;
	width: 100%;
	box-shadow: 2px 2px 10px 0px rgba(90, 90, 90, 1);
}

table.mark-last-row td:nth-last-child(1) {
	background-color: lightcoral;
}

table.sorting tr {
	animation-name: sorting;
	animation-duration: 0.7s;
	animation-fill-mode: forwards;
}

@keyframes sorting {
	100% {
		top: 0;
	}
}
