.transform-container {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.transform-header {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}
